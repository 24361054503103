
          @use "sass:math";
          @import "~@/assets/scss/abstract.scss";
        











































































.loading {
  height: rem(80);
}
